import React from 'react'
import styled from 'styled-components'

const WaterFallCardItemWrap = styled.li`
  list-style-position: inside;
  list-style: inside;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  padding-left: 68px;
`

const WaterFallCardItem = (props) => {
  return <WaterFallCardItemWrap>{props.children}</WaterFallCardItemWrap>
}

export { WaterFallCardItem }
