import React from 'react'
import styled from 'styled-components'

const CardWrap = styled.li`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 56px;

  ${({ theme }) => theme.sm`
    flex: 0 0 50%;
    max-width: 50%;
  `}

  ${({ theme }) => theme.lg`
    margin-bottom: 40px;
  `};

  &.col-3 {
    ${({ theme }) => theme.lg`
      flex: 0 0 25%;
      max-width: 25%;
    `}
  }

  &.col-4 {
    ${({ theme }) => theme.lg`
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    `}
  }
`

const CardImg = styled.img`
  margin-right: 15px;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`

const CardTitle = styled.h3`
  margin: 0;
`

const CardContent = styled.p`
  margin: 0;
`

const TypeCard = (props) => {
  return (
    <CardWrap className={props.space === '4' ? 'col-3' : 'col-4'}>
      <CardImg src={'./assets/icon/' + props.icon + '.svg'} />
      <CardBody>
        <CardTitle>{props.title}</CardTitle>
        <CardContent>{props.content}</CardContent>
      </CardBody>
    </CardWrap>
  )
}

export { TypeCard }
