import React, { Fragment } from 'react'
import { TypeList } from '../../common/list/TypeList'
import { WaterFallsList } from '../../common/list/WaterFallsList'

const ModalProduct = () => {
  return (
    <Fragment>
      <TypeList />
      <WaterFallsList />
    </Fragment>
  )
}

export { ModalProduct }
