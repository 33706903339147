import React, { useContext } from 'react'
import { useHistory } from "react-router-dom";
import styled, { keyframes } from 'styled-components'
import context from '../../context/GlobalContext'

const cardHoverAnimation = keyframes`
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
`

const StlyedCard = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	padding: 60px;
	transition: transform .3s;
	
	&:hover,
	&:focus {
		transform: scale(1.2);
	}
	
	${({ theme }) => theme.xl`
		padding: 100px;
	`};
`

const StyledCardBackground = styled.div`
	animation: ${cardHoverAnimation} 5s infinite linear;
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;

	&::before, &::after {
		background: ${({ theme }) => theme.secondaryColor};
		content: '';
		display: block;
		width: 100px;
		height: 100px;

		${({ theme }) => theme.lg`
			height: 150px;
			width: 150px;
		`};
	}

	&::after {
		left: 0;
		position: absolute;
		transform: rotate(45deg);
	}
`

const StyledWording = styled.span`
	color: ${({ theme }) => theme.primaryColor};
	font-size: 15px;
	font-weight: bold;
	position: absolute;
	z-index: 1;

	${({ theme }) => theme.lg`
		font-size: 22px;
	`};
`

export const TypeCard = (props) => {
	const { setCurrentPage } = useContext(context)
	const history = useHistory()

	const ClickTypeCard = () => {
		history.push(`/select-services/${props.type}`)
		setCurrentPage(null)
	}

	return(
		<StlyedCard onClick={ClickTypeCard}>
			<StyledWording>{props.wording}</StyledWording>
			<StyledCardBackground />
		</StlyedCard>
	)
}
