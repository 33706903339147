import { css } from 'styled-components'

const sizes = {
	xl: 1920,
	lg: 1440,
	md: 1024,
	sm: 768,
	xs: 375
}

export default Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (min-width: ${sizes[label]}px) {
			${css(...args)};
		}
	`
	return acc
}, {})
