import React from 'react'

import { ThemeProvider } from 'styled-components'

import media from './media'

const Theme = ({ children }) => (
   <ThemeProvider
      theme={{
       primaryFont: 'Noto Sans TC',
       primaryColor: '#F6DB4C',
       secondaryColor: '#222222',
       whiteColor: '#FFFFFF',
       radius: '5px',
       ...media
      }}>
      {children}
   </ThemeProvider>
)
export default Theme
