import React from 'react'
import styled from 'styled-components'
import { TypeCard } from '../card/TypeCard'

const List = styled.ul`
  padding: 0 26px;
  display: flex;
  flex-wrap: wrap;
`

const TypeList = (props) => {
  const space = props.space || "3"
  const productTypeArr = props.productType || [
    {
      icon: 'shop',
      title: '電子商務',
      content: '銷售平台、金物流串接、訂單管理、會員管理系統等。 ',
    },
    {
      icon: 'social',
      title: '社交通訊',
      content: '社交平台、即時通訊、視訊聊天、交友媒合系統等。',
    },
    {
      icon: 'media',
      title: '線上媒體',
      content: '音樂廣播、影視戲劇、媒體播放器、行動直播、線上書店、線上可翻頁電子書等。',
    },
    {
      icon: 'platform',
      title: '內容平台',
      content: '新聞潮流、有聲書、部落格、論壇或其他內容平台。',
    },
    {
      icon: 'learn',
      title: '學習資源',
      content: '線上教學平台、語⾔學習系統、字典翻譯、百科全書等。',
    },
    {
      icon: 'health',
      title: '健康醫療',
      content: '運動健身 APP、健康管理軟體、醫院診所系統等。',
    },
    {
      icon: 'business',
      title: '商業辦公',
      content: '資訊管理系統、電子文書系統、雲端儲存、POS系統串接等。',
    },
    {
      icon: 'qrcode',
      title: '電腦應⽤',
      content: '計算機、瀏覽器外掛、QR Code、鍵盤輸入法等。',
    },
    {
      icon: 'life',
      title: '⽣活服務',
      content: '食衣住行相關的搜尋和預訂系統、政府便民服務， 地圖導航、房屋物件管理系統、物聯網軟體開發、美食地圖等。',
    },
    {
      icon: 'pay',
      title: '⾦融⽀付',
      content: '理財記帳、網路銀行、電⼦錢包、行動⽀付等。',
    },
    {
      icon: 'share',
      title: '共享經濟',
      content: '外送平台、交通接送平台、租借平台等。',
    },
    {
      icon: 'bot',
      title: '聊天機器⼈',
      content: '客服、行銷互動等聊天機器⼈。',
    },
  ]
  return (
    <List>
      {productTypeArr.map((item, idx) => {
        return <TypeCard key={idx} icon={item.icon} title={item.title} content={item.content} space={space}></TypeCard>
      })}
    </List>
  )
}

export { TypeList }
