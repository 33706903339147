import React from 'react'
import styled from 'styled-components'

const ModalAboutWrap = styled.div`
  /* ${({ theme }) => theme.xs`
      padding-top: 46px;
  `}
  ${({ theme }) => theme.sm`
      padding-top: 46px;
  `}
  ${({ theme }) => theme.md`
      padding-top: 96px;
  `} */
`
const ModalAboutTitle = styled.h2`
  background-color: #222222;
  text-align: center;
  font-weight: bold;
  line-height: 1.2;
  color: #f6db4c;
  margin: 0;
  ${({ theme }) => theme.xs`
    font-size: 20px;
    padding: 12px 0;
  `}
  ${({ theme }) => theme.md`
    padding: 18px 0;
    font-size: 24px;
  `}
`
const ModalAboutContent = styled.p`
  margin: 0;
  ${({ theme }) => theme.xs`
    padding: 16px;
  `}
  ${({ theme }) => theme.sm`
    padding: 24px;
  `}
  ${({ theme }) => theme.md`
    padding: 34px 84px 44px 84px;
    margin: 0;
    font-size: 24px;
    line-height: 1.25;
    color: #222222;
  `}
  br {
    padding-bottom: 34px;
  }
  &.secondParagraph {
    padding-top: 0;
  }
`
const ModalAbout = () => {
  return (
    <ModalAboutWrap>
      <ModalAboutTitle>品牌故事</ModalAboutTitle>
      <ModalAboutContent>
        <p>智禾數位有限公司成立於2014年, 台北。自2018年6月起，我們的版圖擴展到了吉隆坡。<br />「智」表示以智慧取勝，「禾」則是一粒麥子，「智禾」運用智慧把一粒麥子種在對的地方，最終將獲得一片金黃色的稻田。一粒麥子，一片稻田，這就是網路數位的力量，滿載而歸。</p>
      </ModalAboutContent>
      <ModalAboutTitle>為什麼要選擇智禾？</ModalAboutTitle>
      <ModalAboutContent>
        <p>你也是那種不輕易放過腦袋的人嗎？先是天馬行空，而後靈光乍現，佩服自己的創意也成了日常。<br />然而，如果要實現，到底要找哪些人？用多少成本？怎麼執行？最後，這個創意終究被太多的疑問所淹沒，從此不復存在。<br />智禾的理念為「創意不應該被技術所侷限」，智禾的團隊中有工程師、設計師、行銷專家， 豐富且多元的技術含 UI、UX、程式開發、行銷企劃等，任何想法在合作下都會被實現。<br />所以，盡情解放腦袋吧，智禾團隊可不想放棄你的創意，相信智禾，我們很合。</p>
      </ModalAboutContent>
    </ModalAboutWrap>
  )
}

export { ModalAbout }
