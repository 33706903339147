import React, { useContext } from 'react'
import styled from 'styled-components';
import context from '../../context/GlobalContext'

const StyledItem = styled.div`
	background: #ffffff;
	border-radius: 50%;
	height: 10px;
	text-indent: -9999px;
	transition: transform .2s;
	width: 10px;

	&:hover,
	&:active,
	&.active {
		transform: scale(2);
	}
`

export const Item = (props) => {
	const { currentPage, setCurrentPage } = useContext(context)

	const clickItem = (target) => {
		setCurrentPage((prevState) => (prevState = target))
	}

	return (
		<StyledItem
			onClick={(e) => {
				e.preventDefault()
				clickItem(props.target)
			}}
			className={ props.target === currentPage ? 'active': '' }
		>
			{props.children}
		</StyledItem>
	)
}
