import React from 'react'
import styled from 'styled-components'

const StyledCard = styled.div`
	align-items: center;
	background: ${({theme}) => theme.secondaryColor};
	border-radius: 50%;
	border: 6px solid ${({theme}) => theme.primaryColor};
	box-shadow: 0 0 0 6px ${({theme}) => theme.secondaryColor};
	box-sizing: border-box;
	color: ${({theme}) => theme.primaryColor};
	display: flex;
	font-size: 12px;
	font-weight: bold;
	height: 140px;
	justify-content: center;
	margin: 20px auto;
	padding: 10px;
	text-align: center;
	width: 140px;

	${({ theme }) => theme.sm`
		font-size: 13px;
		height: 164px;
		width: 164px;
	`}

	${({ theme }) => theme.md`
		border-width: 10px;
		box-shadow: 0 0 0 10px ${({theme}) => theme.secondaryColor};
		font-size: 13px;
	`}

	${({ theme }) => theme.lg`
		height: 200px;
		font-size: 18px;
		width: 200px;
	`};

	/* ${({ theme }) => theme.xl`
		height: 230px;
		width: 230px;
	`}; */
`

export const WorkflowCard = (props) => {
	const { order, wording } = props
	return <StyledCard>{order}. { wording }</StyledCard>
}
