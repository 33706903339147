import React from 'react'
import styled from 'styled-components'
import { WaterFallsList } from '../../common/list/WaterFallsList.js'

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.sm`
    padding: 0 30px;
  `};
`
const StyledHeader = styled.div`
  flex: 1;
  margin-bottom: 1rem;

  ${({ theme }) => theme.sm`
		align-items: stretch;
		display: flex;
	`};
`
const StyledSlogan = styled.h2`
  align-items: center;
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.primaryColor};
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  line-height: 1.2;
  margin: 0;
  padding: 12px 0 18px;

  ${({ theme }) => theme.sm`
		border-radius: ${({ theme }) => theme.radius};
		flex: 1;
		padding: 8px 78px 12px;
		font-size: 31px;
	`}
  ${({ theme }) => theme.md`
		font-size: 45px;
		padding: 18px 0 24px;
	`}

	span {
    display: inline-block;
  }
`
const StyledTitle = styled.div`
  background: url(${(props) => props.bg}) no-repeat center center;
  background-size: cover;
  align-items: center;
  color: ${({ theme }) => theme.whiteColor};
  display: flex;
  justify-content: center;
  padding-bottom: calc(100% * 300 / 1470);

  ${({ theme }) => theme.sm`
		border-radius: ${({ theme }) => theme.radius};
		padding-bottom: calc(100% * 250 / 1470);
		flex: 3;
		margin-left: 20px;
	`}

  ${({ theme }) => theme.lg`
		padding-bottom: calc(100% * 220 / 1470);
		flex: 2;
	`};
`
const StyledContent = styled.div`
  flex: 1;
  overflow: auto;
`
const StyledCover = styled.div`
  background: url(${(props) => props.bg}) no-repeat center center / cover;
  padding-bottom: calc(100% * 860 / 2230);
  text-indent: -9999px;

  ${({ theme }) => theme.sm`
		border-radius: ${({ theme }) => theme.radius};
	`};
`
const StyledWording = styled.div`
  background: ${({ theme }) => theme.whiteColor};
  border-radius: ${({ theme }) => theme.radius};
  font-size: 15px;
  margin: 1rem;
  padding: 1.1em 1.1em 1.1em 1.2em;

  ${({ theme }) => theme.sm`
		margin: 1rem 0;
	`};

  ${({ theme }) => theme.lg`
		font-size: 24px;
	`};
`
const StyledSpecTitle = styled.h3`
  background: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.primaryColor};
  display: inline-flex;
  padding: 0.5em 3em;
  border-radius: 0 ${({ theme }) => theme.radius} ${({ theme }) => theme.radius} 0;

  ${({ theme }) => theme.sm`
		border-radius: ${({ theme }) => theme.radius};
	`};
`
const StyledWaterFallsList = styled.div`
  padding: 0 20px;

  ${({ theme }) => theme.sm`
    padding: 0;
  `};
`

const ModalWork = (props) => {
  // const [currentPosition, setPosition] = useState(0)
  const data = [
    {
      name: 'nownews',
      label: 'NowNews 網站',
      wording:
        'Nownews 是⼀個網頁新聞平台，技術範圍包含網頁程式撰寫、使用者體驗規劃、網頁編排、後台功能撰寫等；於成果表現，智禾創造了全國前十大流量的新聞平台，且該平台能夠承受極大瞬間流量。從該作品規模可以得知智禾數位面對多大挑戰都可將之克服並完成。',
      list: [
        {
          icon: 'members',
          title: '會員系統',
          content: [
            'Email 註冊',
            '⼿機號碼註冊',
            'Facebook/Google 註冊',
            '會員卡管理',
            '登入/登出功能',
            '內部 API 對接',
            '認證機制',
            '權限管理',
          ],
        },
        {
          icon: 'ux',
          title: '使⽤者介⾯設計',
          content: [
            'UX 消費者體驗流程研究',
            'UX 消費者架構規劃',
            'UX 策略規劃',
            'UX 測試⽅法設計',
            'UI 視覺體驗設計',
            'UI 互動流程設計',
          ],
        },
        {
          icon: 'analysis',
          title: '數據分析技術',
          content: ['雲端技術', '資料蒐集', '資料預處理', '資料分析'],
        },
        {
          icon: 'analysis',
          title: '報表技術',
          content: ['資料串接', '數據計算與整合', '統計報表⾃動⽣成'],
        },
        {
          icon: 'player',
          title: '多媒體播放技術',
          content: ['多媒體空間串接', '多媒體存取系統', '嵌入影⽚技術'],
        },
        {
          icon: 'cms',
          title: '內容管理技術',
          content: [
            '內部 API 對接',
            '內容編輯與儲存',
            '後台管理系統',
            '⾃動化分類管理系統',
            '內容整合與應⽤',
            '內容備份系統',
            '檔案相容技術',
          ],
        },
        {
          icon: 'realtime',
          title: '即時資訊串連技術',
          content: ['各端即時資訊轉換', '功能即時更新', '資訊整合系統'],
        },
        {
          icon: 'email',
          title: '電⼦郵件技術',
          content: ['Email 系統串接', '郵寄⾃動化功能'],
        },
        {
          icon: 'group',
          title: '社群系統',
          content: ['Facebook 對接', 'Line 對接', 'Twitter 對接', '會員網絡系統', '會員媒合系統', '內容分享系統'],
        },
      ],
    },
    {
      name: 'shuangshuang',
      label: 'Shuang 個人網站',
      wording:
        '此作品為智禾協助個人建立形象網站，除了符合客製化需求，UX 或 UI 也都經過專業設計，且利用多種效果讓網站更加活潑，讓使用者在流程中可以有更大的互動意願。把網站需求交給智禾，專業感、設計感都將一次掌握。',
      list: [
        {
          icon: 'members',
          title: '會員系統',
          content: [
            'Email 註冊',
            '⼿機號碼註冊',
            'Facebook/Google 註冊',
            '會員卡管理',
            '登入/登出功能',
            '內部 API 對接',
            '認證機制',
            '權限管理',
          ],
        },
        {
          icon: 'ux',
          title: '使⽤者介⾯設計',
          content: [
            'UX 消費者體驗流程研究',
            'UX 消費者架構規劃',
            'UX 策略規劃',
            'UX 測試⽅法設計',
            'UI 視覺體驗設計',
            'UI 互動流程設計',
          ],
        },
        {
          icon: 'analysis',
          title: '數據分析技術',
          content: ['雲端技術', '資料蒐集', '資料預處理', '資料分析'],
        },
        {
          icon: 'analysis',
          title: '報表技術',
          content: ['資料串接', '數據計算與整合', '統計報表⾃動⽣成'],
        },
        {
          icon: 'player',
          title: '多媒體播放技術',
          content: ['多媒體空間串接', '多媒體存取系統', '嵌入影⽚技術'],
        },
        {
          icon: 'cms',
          title: '內容管理技術',
          content: [
            '內部 API 對接',
            '內容編輯與儲存',
            '後台管理系統',
            '⾃動化分類管理系統',
            '內容整合與應⽤',
            '內容備份系統',
            '檔案相容技術',
          ],
        },
        {
          icon: 'realtime',
          title: '即時資訊串連技術',
          content: ['各端即時資訊轉換', '功能即時更新', '資訊整合系統'],
        },
        {
          icon: 'email',
          title: '電⼦郵件技術',
          content: ['Email 系統串接', '郵寄⾃動化功能'],
        },
        {
          icon: 'group',
          title: '社群系統',
          content: ['Facebook 對接', 'Line 對接', 'Twitter 對接', '會員網絡系統', '會員媒合系統', '內容分享系統'],
        },
      ],
    },
    {
      name: 'enneagram',
      label: 'enneagram 個人網站',
      wording:
        '此作品為智禾協助業主建立一頁式功能網站，針對客製化需求串接金流，另也開設內容行銷版塊，讓商品可在網站內被內容包裝。另外，UX 或 UI 經過討論與專業設計，讓使用者流程更加順暢。由此作品可知，智禾可以針對不同的需求進行客製，打造比客戶心目中更加完美的網站。',
      list: [
        {
          icon: 'members',
          title: '會員系統',
          content: [
            'Email 註冊',
            '⼿機號碼註冊',
            'Facebook/Google 註冊',
            '會員卡管理',
            '登入/登出功能',
            '內部 API 對接',
            '認證機制',
            '權限管理',
          ],
        },
        {
          icon: 'ux',
          title: '使⽤者介⾯設計',
          content: [
            'UX 消費者體驗流程研究',
            'UX 消費者架構規劃',
            'UX 策略規劃',
            'UX 測試⽅法設計',
            'UI 視覺體驗設計',
            'UI 互動流程設計',
          ],
        },
        {
          icon: 'analysis',
          title: '數據分析技術',
          content: ['雲端技術', '資料蒐集', '資料預處理', '資料分析'],
        },
        {
          icon: 'analysis',
          title: '報表技術',
          content: ['資料串接', '數據計算與整合', '統計報表⾃動⽣成'],
        },
        {
          icon: 'player',
          title: '多媒體播放技術',
          content: ['多媒體空間串接', '多媒體存取系統', '嵌入影⽚技術'],
        },
        {
          icon: 'search',
          title: '搜尋技術',
          content: ['資料庫串接', '關鍵字媒合系統'],
        },
        {
          icon: 'cms',
          title: '內容管理技術',
          content: [
            '內部 API 對接',
            '內容編輯與儲存',
            '後台管理系統',
            '⾃動化分類管理系統',
            '內容整合與應⽤',
            '內容備份系統',
            '檔案相容技術',
          ],
        },
        {
          icon: 'realtime',
          title: '即時資訊串連技術',
          content: ['各端即時資訊轉換', '功能即時更新', '資訊整合系統'],
        },
        {
          icon: 'email',
          title: '電⼦郵件技術',
          content: ['Email 系統串接', '郵寄⾃動化功能'],
        },
        {
          icon: 'cash',
          title: '金流系統',
          content: [
            '銀行對接',
            '綠界/Paypal/藍新 SDK 對接',
            '超商 SDK 對接',
            'Line / Apple Pay 對接',
            '其他⽀付系統對接',
          ],
        },
      ],
    },
    {
      name: 'holywater',
      label: 'holywater app',
      wording:
        'Holywater 是⼀款天主教的⽣活服務軟體，當中將許多傳統宗教儀式改為線上執⾏模式，其更結合社群通訊功能（含直播、貼⽂、按讚、分享功能等），將宗教現代化，使教友在⽣活上更加便利。 ',
      list: [
        {
          icon: 'members',
          title: '會員系統',
          content: [
            'Email 註冊',
            '⼿機號碼註冊',
            'Facebook/Google 註冊',
            '會員卡管理',
            '登入/登出功能',
            '內部 API 對接',
            '認證機制',
            '權限管理',
          ],
        },
        {
          icon: 'cms',
          title: '內容管理技術',
          content: [
            '內部 API 對接',
            '內容編輯與儲存',
            '後台管理系統',
            '⾃動化分類管理系統',
            '內容整合與應⽤',
            '內容備份系統',
            '檔案相容技術',
          ],
        },
        {
          icon: 'group',
          title: '社群系統',
          content: ['Facebook 對接', 'Line 對接', 'Twitter 對接', '會員網絡系統', '會員媒合系統', '內容分享系統'],
        },
        {
          icon: 'ux',
          title: '使⽤者介⾯設計',
          content: [
            'UX 消費者體驗流程研究',
            'UX 消費者架構規劃',
            'UX 策略規劃',
            'UX 測試⽅法設計',
            'UI 視覺體驗設計',
            'UI 互動流程設計',
          ],
        },
        {
          icon: 'realtime',
          title: '即時資訊串連技術',
          content: ['各端即時資訊轉換', '功能即時更新', '資訊整合系統'],
        },
        {
          icon: 'photo',
          title: '影像技術',
          content: ['拍照功能', '錄音功能', '錄影功能', '相片編輯系統', '音檔編輯系統'],
        },
        {
          icon: 'player',
          title: '多媒體播放技術',
          content: ['多媒體空間串接', '多媒體存取系統', '嵌入影⽚技術'],
        },
        {
          icon: 'analysis',
          title: '數據分析技術',
          content: ['雲端技術', '資料蒐集', '資料預處理', '資料分析'],
        },
        {
          icon: 'analysis',
          title: '報表技術',
          content: ['資料串接', '數據計算與整合', '統計報表⾃動⽣成'],
        },
        {
          icon: 'streaming',
          title: '直播技術',
          content: ['採集影像、音檔', '影像處理', '編碼解碼', '推流', '流媒體伺服器', '拉流'],
        },
        {
          icon: 'noti',
          title: '推播提醒技術',
          content: ['伺服器串接', '自動化設定'],
        },
      ],
    },
    {
      name: 'icbpncc',
      label: 'icbpncc APP',
      wording:
        '愛兒麗是⼀款護理之家專屬系統，該款 APP 使⽤ IOT 物聯網技術將設備介接⼿機，讓使⽤者可以隨時監控媽媽與寶寶身體徵象並予以紀錄，使護理之家在照顧媽媽與寶寶時更加⽅便。 ',
      list: [
        {
          icon: 'members',
          title: '會員系統',
          content: [
            'Email 註冊',
            '⼿機號碼註冊',
            'Facebook/Google 註冊',
            '會員卡管理',
            '登入/登出功能',
            '內部 API 對接',
            '認證機制',
            '權限管理',
          ],
        },
        {
          icon: 'ux',
          title: '使⽤者介⾯設計',
          content: [
            'UX 消費者體驗流程研究',
            'UX 消費者架構規劃',
            'UX 策略規劃',
            'UX 測試⽅法設計',
            'UI 視覺體驗設計',
            'UI 互動流程設計',
          ],
        },
        {
          icon: 'iot',
          title: 'IOT 技術',
          content: ['埋點追蹤', '感測器介接與整合'],
        },
        {
          icon: 'analysis',
          title: '數據分析技術',
          content: ['雲端技術', '資料蒐集', '資料預處理', '資料分析'],
        },
        {
          icon: 'analysis',
          title: '報表技術',
          content: ['資料串接', '數據計算與整合', '統計報表⾃動⽣成'],
        },
      ],
    },
    {
      name: 'evoxen',
      label: 'evoxen APP',
      wording:
        'Evoxen 是⼀個與馬來西亞停車場合作的專案，此款 APP 使⽤ IOT 物聯網系統將車位管理系統與⼿機介接，消費者停車後，會在⼿機產⽣專屬付費 QR Code，另外除了透過 QR Code 繳費外，該 APP 更結合了金融系統，讓消費者可以在線上繳交停車費，突破了傳統停車場的思維與運作⽅式。',
      list: [
        {
          icon: 'members',
          title: '會員系統',
          content: [
            'Email 註冊',
            '⼿機號碼註冊',
            'Facebook/Google 註冊',
            '會員卡管理',
            '登入/登出功能',
            '內部 API 對接',
            '認證機制',
            '權限管理',
          ],
        },
        {
          icon: 'ux',
          title: '使⽤者介⾯設計',
          content: [
            'UX 消費者體驗流程研究',
            'UX 消費者架構規劃',
            'UX 策略規劃',
            'UX 測試⽅法設計',
            'UI 視覺體驗設計',
            'UI 互動流程設計',
          ],
        },
        {
          icon: 'iot',
          title: 'IOT 技術',
          content: ['埋點追蹤', '感測器介接與整合'],
        },
        {
          icon: 'analysis',
          title: '數據分析技術',
          content: ['雲端技術', '資料蒐集', '資料預處理', '資料分析'],
        },
        {
          icon: 'analysis',
          title: '報表技術',
          content: ['資料串接', '數據計算與整合', '統計報表⾃動⽣成'],
        },
        {
          icon: 'cms',
          title: '內容管理技術',
          content: [
            '內部 API 對接',
            '內容編輯與儲存',
            '後台管理系統',
            '⾃動化分類管理系統',
            '內容整合與應⽤',
            '內容備份系統',
            '檔案相容技術',
          ],
        },
        {
          icon: 'realtime',
          title: '即時資訊串連技術',
          content: ['各端即時資訊轉換', '功能即時更新', '資訊整合系統'],
        },
        {
          icon: 'photo',
          title: '影像技術',
          content: ['拍照功能', '錄音功能', '錄影功能', '相片編輯系統', '音檔編輯系統'],
        },
        {
          icon: 'cash',
          title: '金流系統',
          content: [
            '銀行對接',
            '綠界/Paypal/藍新 SDK 對接',
            '超商 SDK 對接',
            'Line / Apple Pay 對接',
            '其他⽀付系統對接',
          ],
        },
        {
          icon: 'email',
          title: '電⼦郵件技術',
          content: ['Email 系統串接', '郵寄⾃動化功能'],
        },
        {
          icon: 'noti',
          title: '推播提醒技術',
          content: ['伺服器串接', '自動化設定'],
        },
      ],
    },
  ]
  return (
    <StyledModal
    // onTouchStart={(e) => {
    //   console.log('touchStart', e.nativeEvent)
    //   setPosition((prevStates) => (prevStates = e.nativeEvent.changedTouches[0].clientX))
    // }}
    // onTouchEnd={(e) => {
    //   if (e.nativeEvent.changedTouches[0].clientX - currentPosition > 0) {
    //     console.log('right')
    //   } else {
    //     console.log('left')
    //   }
    // }}
    >
      <StyledHeader>
        <StyledSlogan>
          <span>你不用會</span>
          <span>我來實現</span>
        </StyledSlogan>
        <StyledTitle bg={`./assets/images/works/${data[props.index].name}.png`} />
      </StyledHeader>
      <StyledContent>
        <StyledCover bg={`./assets/images/works/${data[props.index].name}_cover.png`}>
          {data[props.index].label}截圖
        </StyledCover>
        <StyledWording>{data[props.index].wording}</StyledWording>
        <StyledSpecTitle>我們使用了以下技術</StyledSpecTitle>
        <StyledWaterFallsList>
          <WaterFallsList list={data[props.index].list} />
        </StyledWaterFallsList>
      </StyledContent>
    </StyledModal>
  )
}

export { ModalWork }
