import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Pagination } from '../../layout/Pagination'
import { Container } from '../../layout/Container'
import { Header } from '../../layout/Header'
import { Title } from '../../layout/Title'
import { WorkflowCard } from './WorkflowCard'

const StyledPage = styled.section`
	background: ${({ theme }) => theme.primaryColor};
	display: block;
	height: 100vh;
	overflow: hidden;
	position: relative;
	width: 100vw;
`

const StyledList = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const StyledItem = styled.div`
	width: 50%;

	${({ theme }) => theme.sm`
		width: 33%;
	`}

	${({ theme }) => theme.xl`
		width: auto;
		flex: 1;
	`}
`

export const WorkflowPage = () => {
	const [workflowList, setWorkflowList] = useState([])

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = () => {
		let fetchedData = [
			{
				wording: '需求分析與討論',
			},
			{
				wording: '產品企劃討論',
			},
			{
				wording: 'UX使用者體驗流程設計',
			},
			{
				wording: 'UI使用者視覺設計',
			},
			{
				wording: '程式開發與測試',
			},
			{
				wording: '產品上線與維護',
			},
		]

		setWorkflowList((state) => fetchedData)
	}

	return (
		<StyledPage>
			<Header color="dark" />
			<Title title="合作流程" color="dark" />
			<Pagination />
			<Container>
			<StyledList>
				{workflowList.map((item, i) => {
					return (
						<StyledItem key={i}>
							<WorkflowCard key={i} order={i+1} wording={item.wording} />
						</StyledItem>
					)
				})}
			</StyledList>
			</Container>
		</StyledPage>
	)
}
