import React, { forwardRef } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
	box-sizing: border-box;
	height: 80vh;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	max-width: 1400px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 10px;

		&-track {
			border-radius: 10px;
			background: ${({ theme }) => theme.primaryColor};
			border: 3px solid ${({ theme }) => theme.secondaryColor};
		}

		&-thumb {
			border-radius: 10px;
			background: ${({ theme }) => theme.secondaryColor};
		}
	}
`

export const Container = forwardRef((props, ref) => <StyledContainer ref={ref}>{props.children}</StyledContainer>)
