import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Pagination } from '../../layout/Pagination'
import { Header } from '../../layout/Header'
import { Title } from '../../layout/Title'
import { TypeCard } from './TypeCard'

const StyledPage = styled.section`
	background: ${({ theme }) => theme.secondaryColor};
	display: block;
	height: 100vh;
	overflow: hidden;
	width: 100vw;
`

const StyledContainer = styled.div`
	align-items: center;
	background: ${({ theme }) => theme.primaryColor};
	display: flex;
	height: 100vh;
	justify-content: center;
	left: 50%;
	margin: 0 auto;
	position: absolute;
	top: 0;
	transform: translateX(-50%);
	width: 250px;
`

const StyledList = styled.div`
	display: flex;
	flex-direction: column;
`

export const SelectTypesPage = () => {
	const [cardList, setCardList] = useState([])

	const fetchData = () => {
		let fetchedData = [
			{
				wording: 'APP',
				type: 'app',
			},
			{
				wording: '網站/系統',
				type: 'web',
			},
		]

		setCardList((state) => fetchedData)
	}
	
	useEffect(() => {
		fetchData()
	}, [])

	return(
		<StyledPage>
			<Header color="light" />
			<Title title="您的需求屬於：" color="light" />
			<Pagination />
			<StyledContainer>
				<StyledList>
					{cardList.map(item => (
						// <Link to={{pathname: `/select-services/${item.type}`}}>
						<TypeCard wording={item.wording} type={item.type} key={item.type} />
						// </Link>
					))}
				</StyledList>
			</StyledContainer>
		</StyledPage>
	)
}
