import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import { withRouter } from 'react-router'
import { MainPage } from './pages/MainPage/'
import { StatusPage } from './pages/StatusPage/'
import { WorkflowPage } from './pages/WorkflowPage/'
import { SelectTypesPage } from './pages/SelectTypesPage/'
import { SelectServicesPage } from './pages/SelectServicesPage'
import { Modal } from './layout/Modal/'
import { Provider } from './context/GlobalContext'

const tagManagerArgs = {
	gtmId: 'GTM-K7JPZVC',
}

if (process.browser) {
	TagManager.initialize(tagManagerArgs)
}

const App = (props) => {
	const isClient = typeof window === 'object'

	const getSize = () => {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
		}
	}

	const [currentPage, setCurrentPage] = useState(0)
	const [modal, setModalStatus] = useState(null)
	const [pauseListener, setListener] = useState(false)
	const [menuStatus, setMenuStatus] = useState({
		logoPlayed: false,
		listPlayed: false,
		burgerPlayed: false,
		open: false,
	})
	const [windowSize, setWindowSize] = useState(getSize())
	const routerArr = ['/', '/status', '/workflow', '/select-types']

	const clickPrevButton = () => {
		if (currentPage === 0) return
		setCurrentPage((prevState) => prevState - 1)
	}
	const clickNextButton = () => {
		if (currentPage > routerArr.length - 2) return
		setCurrentPage((prevState) => prevState + 1)
	}

	const contextValue = {
		currentPage,
		clickNextButton,
		clickPrevButton,
		modal,
		setModalStatus,
		setCurrentPage,
		menuStatus,
		setMenuStatus,
		setListener,
		windowSize,
		setWindowSize,
		routerArr,
	}

	const scrollUp = () => {
		if (windowSize.width >= 768 && currentPage !== null) {
			if (currentPage === 0) return
			setCurrentPage((prevState) => prevState - 1)
		}
	}

	const scrollDown = () => {
		if (windowSize.width >= 768 && currentPage !== null) {
			if (currentPage > routerArr.length - 2) return
			setCurrentPage((prevState) => prevState + 1)
		}
	}

	useEffect(() => {
		if (!isClient) {
			return false
		}

		function handleResize() {
			setWindowSize(getSize())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		props.history.push(routerArr[currentPage])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage])

	return (
		<Provider value={contextValue}>
			<ReactScrollWheelHandler upHandler={scrollUp} downHandler={scrollDown} pauseListeners={pauseListener}>
				<div className="container">
					<Route path="/" exact component={MainPage} />
					<Route path="/status" component={StatusPage} />
					<Route path="/workflow" component={WorkflowPage} />
					<Route path="/select-types" component={SelectTypesPage} />
					<Route path="/select-services/:id(app|web)?/" exact component={SelectServicesPage} />
				</div>
				<Modal></Modal>
			</ReactScrollWheelHandler>
		</Provider>
	)
}

export default withRouter(App)
