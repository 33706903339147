import React from 'react'
import styled from 'styled-components'
import { WaterFallCard } from '../card/WaterFallCard'

const WaterFallsListWrap = styled.ul`
  padding-left: 0;
  column-width: 300px;
  column-gap: 25px;
  padding: ${(props) => (props.list ? '0' : '0 20px')};

  ${({ theme }) => theme.sm`
    padding: ${(props) => (props.list ? '0' : '0 38px')};
  `};
`

const WaterFallsListTitle = styled.li`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 150px;
  border-radius: 5px;
  background-color: #222222;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  color: #f6db4c;
  margin-bottom: 25px;
  word-break: keep-all;
  text-align: center;
`

const WaterFallsList = (props) => {
  const SystemItemArr = props.list || [
    {
      title: '你不用會 我來實現',
    },
    {
      icon: 'members',
      title: '會員系統',
      content: [
        'Email 註冊',
        '⼿機號碼註冊',
        'Facebook/Google 註冊',
        '會員卡管理',
        '登入/登出功能',
        '內部 API 對接',
        '認證機制',
        '權限管理',
      ],
    },
    {
      icon: 'analysis',
      title: '數據分析技術',
      content: ['雲端技術', '資料蒐集', '資料預處理', '資料分析'],
    },
    {
      icon: 'streaming',
      title: '通訊與直播技術',
      content: ['採集影像、⾳檔', '影像處理', '編碼解碼', '推流', '流媒體伺服器', '拉流'],
    },
    {
      icon: 'search',
      title: '搜尋技術',
      content: ['資料庫串接', '關鍵字媒合系統'],
    },
    {
      icon: 'photo',
      title: '影像',
      content: [
        '拍照功能',
        '錄⾳功能',
        '錄影功能',
        '相⽚編輯系統',
        '內容整合與應⽤',
        '內容備份系統',
        '檔案相容技術',
        '⾳檔編輯系統',
      ],
    },
    {
      icon: 'noti',
      title: '推播提醒技術',
      content: ['伺服器串接', '⾃動化設定'],
    },
    {
      icon: 'ux',
      title: '使⽤者介⾯設計',
      content: [
        'UX 消費者體驗流程研究',
        'UX 消費者架構規劃',
        'UX 策略規劃',
        'UX 測試⽅法設計',
        'UI 視覺體驗設計',
        'UI 互動流程設計',
      ],
    },
    {
      icon: 'analysis',
      title: '報表技術',
      content: ['資料串接', '數據計算與整合', '統計報表⾃動⽣成'],
    },
    {
      icon: 'player',
      title: '多媒體播放技術',
      content: ['多媒體空間串接', '多媒體存取系統', '嵌入影⽚技術'],
    },
    {
      icon: 'cms',
      title: '內容管理技術',
      content: [
        '內部 API 對接',
        '內容編輯與儲存',
        '後台管理系統',
        '⾃動化分類管理系統',
        '內容整合與應⽤',
        '內容備份系統',
        '檔案相容技術',
      ],
    },
    {
      icon: 'cash',
      title: '⾦流系統',
      content: ['銀⾏對接', '綠界/Paypal/藍新 SDK 對接', '超商 SDK 對接', 'Line / Apple Pay 對接', '其他⽀付系統對接'],
    },
    {
      icon: 'iot',
      title: 'IOT 技術',
      content: ['埋點追蹤', '感測器介接與整合', '藍芽系統', 'QR code 應⽤'],
    },
    {
      icon: 'gps',
      title: 'GPS 與地圖系統',
      content: ['打卡技術', '位置軌跡追蹤', '叢集技術', '商家資訊串連', '地理資訊串連', '導航技術', 'Google maps 串接'],
    },
    {
      icon: 'chatbot',
      title: '聊天機器⼈(Chatbot)',
      content: ['Chatbot ⾏銷企劃', 'Chatbot 購物整合', 'Chatbot 客⼾服務', 'Chatbot 資料建入'],
    },
    {
      icon: 'realtime',
      title: '即時資訊串連技術',
      content: ['各端即時資訊轉換', '功能即時更新', '資訊整合系統'],
    },
    {
      icon: 'email',
      title: '電⼦郵件技術',
      content: ['Email 系統串接', '郵寄⾃動化功能'],
    },
    {
      icon: 'group',
      title: '社群系統',
      content: ['Facebook 對接', 'Line 對接', 'Twitter 對接', '會員網絡系統', '會員媒合系統', '內容分享系統'],
    },
  ]
  return (
    <WaterFallsListWrap list={props.list}>
      {SystemItemArr.map((item, idx) => {
        if (!item.icon) {
          return <WaterFallsListTitle key={idx}>{item.title}</WaterFallsListTitle>
        } else {
          return <WaterFallCard key={idx} icon={item.icon} title={item.title} content={item.content}></WaterFallCard>
        }
      })}
    </WaterFallsListWrap>
  )
}

export { WaterFallsList }
