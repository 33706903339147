import React, { useContext, Fragment, useState } from 'react'
import context from '../../context/GlobalContext'
import { ModalAbout } from '../Modal/ModalAbout'
import { ModalProduct } from '../Modal/ModalProduct'
import { ModalWork } from '../Modal/ModalWork'
import { ModalContact } from '../Modal/ModalContact'
import { ModalTech } from '../Modal/ModalTech'
import styled from 'styled-components'

const StyledModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(34, 34, 34, 0.5);
  z-index: 100;
`

const StyledWindows = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  max-height: 90vh;

  &.about {
    max-width: 1100px;
    border-radius: 20px;
    border: solid 3px #222222;
    background-color: #f6db4c;
    ${({ theme }) => theme.xs`
      width: calc(100% - 12px * 2);
    `}
    ${({ theme }) => theme.md`
      width: calc(100% - 67px * 2);
    `}
  }
  &.product {
    max-width: 1200px;
    border-radius: 20px;
    border: solid 3px #222222;
    background-color: #f6db4c;
    width: calc(100% - 67px * 2);
    ${({ theme }) => theme.xs`
      max-height: 95vh;
      width: calc(100% - 12px * 2);
    `}
    ${({ theme }) => theme.md`
      width: calc(100% - 67px * 2);
    `}
  }
  &.work {
    max-width: 1200px;
    border-radius: 20px;
    border: solid 3px #222222;
    background-color: #f6db4c;
    width: calc(100% - 67px * 2);
    ${({ theme }) => theme.xs`
      max-height: 95vh;
      width: calc(100% - 12px * 2);
    `}
    ${({ theme }) => theme.md`
      width: calc(100% - 67px * 2);
    `}
  }
  &.contact {
    max-width: 1200px;
    border-radius: 20px;
    border: solid 3px #f6db4c;
    background-color: #222222;
    width: calc(100% - 67px * 2);

    &::-webkit-scrollbar {
      width: 0;
      margin-right: 20px;

      &-track {
        border-radius: 10px;
        background: transparent;
        border: 3px solid ${({ theme }) => theme.secondaryColor};
      }

      &-thumb {
        border-radius: 10px;
        background: ${({ theme }) => theme.secondaryColor};
      }
    }
  }
	/* &.about {
		max-width: 1100px;
		border-radius: 20px;
		border: solid 3px #222222;
		background-color: #f6db4c;
		${({ theme }) => theme.xs`
			max-height: 95vh;
			overflow: auto;
			width: calc(100% - 12px * 2);
		`}
		${({ theme }) => theme.md`
			width: calc(100% - 67px * 2);
		`}
	}

	&.product {
		max-width: 1200px;
		border-radius: 20px;
		border: solid 3px #222222;
		background-color: #f6db4c;
		width: calc(100% - 67px * 2);
		height: 90vh;
		overflow: auto;
	}

	&.work {
		max-width: 1200px;
		border-radius: 20px;
		border: solid 3px #222222;
		background-color: #f6db4c;
		width: calc(100% - 67px * 2);
		height: 90vh;
		overflow: auto;
	} */

	&.contact {
		background-color: ${({ theme }) => theme.secondaryColor};
		border-radius: 20px;
		border: solid 3px ${({ theme }) => theme.primaryColor};
		max-height: 90vh;
		max-width: 700px;
		width: calc(100% - 15px * 2);

		${({ theme }) => theme.md`
			border-color: #222222;
			background-color: #f6db4c;
		`};
	}
`

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 60px;
  justify-content: space-between;
  position: relative;

  ${({ theme }) => theme.md`
    flex-basis: 90px;
  `}

  &.contact {
    display: none;
    ${({ theme }) => theme.md`
      display: flex;
    `};
  }
`

const StyledContent = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledModalCloseBtn = styled.img`
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: 14px;
  width: 24px;

  ${({ theme }) => theme.md`
    height: auto;
    right: 24px;
    top: 24px;
    width: auto;
	`}
`

const ModalSlideLeft = styled.div`
  cursor: pointer;
  position: absolute;
  left: 26px;
  top: 54px;

  ${({ theme }) => theme.xs`
	`};
  ${({ theme }) => theme.md`
		left: 20px;
    top: unset;
	`};
  img {
    display: none;
    ${({ theme }) => theme.md`
      display: block;
    `};
  }
  span {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 16px 10px 0;
    border-color: transparent #222222 transparent transparent;

    ${({ theme }) => theme.md`
      display: none;
    `};
  }
`

const ModalSlideRight = styled.div`
  cursor: pointer;
  position: absolute;
  right: 80px;
  top: 54px;
  ${({ theme }) => theme.xs`
	`};
  ${({ theme }) => theme.md`
		right: 20px;
    top: unset;
	`};
  img {
    display: none;
    ${({ theme }) => theme.md`
		display: block;
	`};
  }
  span {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 16px;
    border-color: transparent transparent transparent #222222;
    ${({ theme }) => theme.md`
      display: none;
    `};
  }
`

const Modal = (props) => {
  const { modal, setModalStatus, setListener } = useContext(context)
  const [currentIndex, setCurrentIndex] = useState(0)
  const renderContent = (modal) => {
    switch (modal) {
      case 1:
        return null
      case 2:
        return <ModalProduct />
      case 3:
        return <ModalWork type={props.type} index={currentIndex} />
      case 4:
        return <ModalAbout />
      case 5:
        return <ModalContact />
      case 6:
        return <ModalTech />
      default:
        return null
    }
  }
  const renderContentClass = (modal) => {
    switch (modal) {
      case 1:
        break
      case 2:
        return 'product'
      case 3:
        return 'about'
      case 4:
        return 'work'
      case 5:
        return 'contact'
      case 6:
        return 'product'
      default:
        return null
    }
  }
  const closeModal = () => {
    setModalStatus((prevState) => (prevState = null))
    setListener((prevState) => (prevState = false))
  }

  const prev = () => {
    if (currentIndex === 0) return
    setCurrentIndex((prevState) => prevState - 1)
  }

  const next = () => {
    if (currentIndex === 5) return
    setCurrentIndex((prevState) => prevState + 1)
  }

  return (
    <Fragment>
      {modal ? (
        <StyledModal>
          <StyledWindows className={renderContentClass(modal)}>
            <StyledHeader className={renderContentClass(modal)}>
            <StyledModalCloseBtn onClick={closeModal} src="./assets/icon/close.svg" />
            </StyledHeader>
            <StyledContent>
            {renderContent(modal)}
            </StyledContent>
          </StyledWindows>
          {currentIndex !== 0 && modal === 3 ? (
            <ModalSlideLeft onClick={prev}>
              <img src="./assets/icon/slide-left.svg" alt="" />
              <span></span>
            </ModalSlideLeft>
          ) : null}
          {currentIndex === 5 || modal !== 3 ? null : (
            <ModalSlideRight onClick={next}>
              <img src="./assets/icon/slide-right.svg" alt="" />
              <span></span>
            </ModalSlideRight>
          )}
        </StyledModal>
      ) : null}
    </Fragment>
  )
}

export { Modal }
