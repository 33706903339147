import React, { useRef, useEffect, useContext } from 'react'
import { TimelineMax } from 'gsap'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { calculateFrame } from '../../common/fps'
import context from '../../context/GlobalContext'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
gsap.registerPlugin(CSSPlugin)

const StyledLogo = styled.img`
	border-color: ${props => props.color === 'light' ? ({ theme }) => theme.secondaryColor : '' };
  border-style: solid;
  border-width: ${props => props.color === 'light' ? '0 3px 4px 0' : '0'};
  height: 40px;
  position: absolute;
  width: 114px;
  z-index: 2;

  ${({ theme }) => theme.sm`
    height: 76px;
    width: 200px;
	`};
`

export const Logo = (props) => {
  const { menuStatus, setMenuStatus, windowSize } = useContext(context)
  const logoRef = useRef(null)

  useEffect(() => {
    if (menuStatus.logoPlayed === true) return
    logoPlayed()
    let logoTL = new TimelineMax()
    if (windowSize.width > 768) {
      logoTL
        .fromTo(
          logoRef.current,
          calculateFrame(7),
          {
            x: 500,
            width: 180,
            height: 154,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            delay: 8,
          }
        )
        .fromTo(
          logoRef.current,
          calculateFrame(1),
          {
            width: 150,
            height: 180,
          },
          {
            width: 240,
            height: 84,
          }
        )
        .fromTo(
          logoRef.current,
          calculateFrame(1),
          {
            width: 240,
            height: 84,
          },
          {
            width: 200,
            height: 76,
          }
        )
    } else {
      logoTL
        .fromTo(
          logoRef.current,
          calculateFrame(7),
          {
            x: 500,
            width: 94,
            height: 70,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            delay: 8,
          }
        )
        .fromTo(
          logoRef.current,
          calculateFrame(1),
          {
            width: 80,
            height: 114,
          },
          {
            width: 134,
            height: 40,
          }
        )
        .fromTo(
          logoRef.current,
          calculateFrame(1),
          {
            width: 134,
            height: 40,
          },
          {
            width: 114,
            height: 40,
          }
        )
    }
  }, [])

  const logoPlayed = () => {
    setMenuStatus(prevState => ({
      ...prevState,
      logoPlayed: !prevState.logoPlayed
    }))
  }

  return (
    <Link to="/">
      <StyledLogo
        color={props.color}
        ref={logoRef}
        src={
          props.color === 'light' ?
          './assets/icon/logo_wh.png'
          :
          './assets/icon/logo.png'
        }
      />
    </Link>
  )
}
