import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.div`
	background: ${props => props.color === 'light' ? ({ theme }) => theme.primaryColor : ({ theme }) => theme.secondaryColor };
	border-color: ${props => props.color === 'light' ? ({ theme }) => theme.secondaryColor : ({ theme }) => theme.primaryColor };
	border-style: solid;
	border-width: ${props => props.fullWidth ? '0' : '3px 3px 3px 0'};
	box-shadow: ${props => props.color === 'light' ? '3px 4px': '' };
	box-sizing: border-box;
	color: ${props => props.color === 'light' ? ({ theme }) => theme.secondaryColor : ({ theme }) => theme.primaryColor };
	display: inline-flex;
	font-size: ${props => props.fullWidth ? '21px' : '25px'};
	font-weight: 900;
	justify-content: ${props => props.fullWidth ? 'center' : 'flex-end'};
	margin-bottom: 20px;
	margin-top: 10vh;
	padding: ${props => props.fullWidth ? '10px': '10px 30px 10px 45px'};
	position: relative;
	width: ${props => props.fullWidth ? '100%' : ''};
	z-index: 1;

	${({ theme }) => theme.sm`
		font-size: 27px;
		padding: ${props => props.fullWidth ? '15px 30px' : '15px 30px 15px 164px'};
	`}

	${({ theme }) => theme.md`
		padding-left: ${props => props.fullWidth ? '30px' : '120px'};
	`}

	${({ theme }) => theme.lg`
		font-size: 40px;
		margin-top: 12vh;
		padding: ${props => props.fullWidth ? '20px 30px 20px 30px' : '20px 30px 20px 170px'};
	`}

	${({ theme }) => theme.xl`
		font-size: 50px;
		margin-top: 18vh;
		padding: ${props => props.fullWidth ? '13px 30px 13px 30px' : '13px 30px 13px 254px'};
	`}
`

const Title = (props) => {
	return (
		<StyledTitle color={props.color} fullWidth={props.fullWidth}>{ props.title }</StyledTitle>
	)
}

export { Title }
