import React from 'react'
import { WaterFallsList } from '../../common/list/WaterFallsList'

const ModalTech = () => {
  return (
    <WaterFallsList />
  )
}

export { ModalTech }
