import React from 'react'
import styled from 'styled-components'
import { WaterFallCardList } from '../list/WaterFallCardList'

const WaterFallCardWrap = styled.li`
  border-radius: 5px;
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  page-break-inside: avoid;
  break-inside: avoid;
  list-style: none;
  margin-bottom: 25px;
  padding-bottom: 30px;
`

const WaterFallCardImg = styled.img`
  margin: 30px 0;
`

const WaterFallCardTitle = styled.h3`
  width: 200px;
  height: 40px;
  background-color: #222;
  margin: 0 0 17px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
  color: #f6db4c;
`

const WaterFallCard = (props) => {
  return (
    <WaterFallCardWrap>
      <WaterFallCardImg src={'./assets/icon/' + props.icon + '.svg'} />
      <WaterFallCardTitle>{props.title}</WaterFallCardTitle>
      <WaterFallCardList content={props.content} />
    </WaterFallCardWrap>
  )
}

export { WaterFallCard }
