import React, { useContext, Fragment } from 'react'
import context from '../../context/GlobalContext'
import Lottie from 'lottie-react-web'
import animation from './M_all.json'
import animationX from './M_all_X.json'
import { Pagination } from '../../layout/Pagination'

const InitAnimationII = () => {
  const { windowSize } = useContext(context)

  return (
    <Fragment>
      <Pagination />
      {windowSize.height < 750 ? (
        <Lottie
          options={{
            animationData: animation,
            loop: false,
          }}
        />
      ) : (
        <Lottie
          options={{
            animationData: animationX,
            loop: false,
          }}
        />
      )}
    </Fragment>
  )
}

export { InitAnimationII }
