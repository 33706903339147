import React from 'react'
import { WaterFallCardItem } from '../card/WaterFallCardItem'
import styled from 'styled-components'

const WaterFallCardCateWrap = styled.ul`
  padding-left: 0;
`
const WaterFallCardList = (props) => {
  return (
    <WaterFallCardCateWrap>
      {props.content.map((item, idx) => {
        return <WaterFallCardItem key={idx}>{item}</WaterFallCardItem>
      })}
    </WaterFallCardCateWrap>
  )
}

export { WaterFallCardList }
