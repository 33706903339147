import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Container } from '../../layout/Container'
import context from '../../context/GlobalContext'
import { Header } from '../../layout/Header'
import { Title } from '../../layout/Title'
import { TypeList } from '../../common/list/TypeList'
import { QuestionCard } from './QuestionCard'

const StyledPage = styled.section`
  background: ${({ theme }) => theme.primaryColor};
  display: block;
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100vw;
`

const StyledList = styled.div`
  flex: 1;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
`

export const SelectServicesPage = (props) => {
  const { id } = props.match.params
  const [data, setData] = useState({})
  const { setModalStatus, setListener } = useContext(context)

  const handleModalOpen = (target) => {
    target = parseInt(target)
    setModalStatus((prevState) => (prevState = target))
    setListener((prevState) => (prevState = true))
  }

  const fetchData = () => {
    let fetchedData = {}

    switch (id) {
      case 'app':
        fetchedData = {
          name: 'APP',
          productType: [
            {
              icon: 'shop',
              title: '電子商務',
              content: '銷售平台、金物流串接、訂單管理、會員管理系統等。 ',
            },
            {
              icon: 'social',
              title: '社交通訊',
              content: '社交平台、即時通訊、視訊聊天、交友媒合系統等。',
            },
            {
              icon: 'media',
              title: '線上媒體',
              content: '音樂廣播、影視戲劇、媒體播放器、行動直播、線上書店、線上可翻頁電子書等。',
            },
            {
              icon: 'platform',
              title: '內容平台',
              content: '新聞潮流、有聲書、部落格、論壇或其他內容平台。',
            },
            {
              icon: 'learn',
              title: '學習資源',
              content: '線上教學平台、語⾔學習系統、字典翻譯、百科全書等。',
            },
            {
              icon: 'health',
              title: '健康醫療',
              content: '運動健身 APP、健康管理軟體、醫院診所系統等。',
            },
            {
              icon: 'business',
              title: '商業辦公',
              content: '資訊管理系統、電子文書系統、雲端儲存、POS系統串接等。',
            },
            {
              icon: 'qrcode',
              title: '電腦應⽤',
              content: '計算機、瀏覽器外掛、QR Code、鍵盤輸入法等。',
            },
            {
              icon: 'life',
              title: '⽣活服務',
              content:
                '食衣住行相關的搜尋和預訂系統、政府便民服務， 地圖導航、房屋物件管理系統、物聯網軟體開發、美食地圖等。',
            },
            {
              icon: 'pay',
              title: '⾦融⽀付',
              content: '理財記帳、網路銀行、電⼦錢包、行動支付等。',
            },
            {
              icon: 'share',
              title: '共享經濟',
              content: '外送平台、交通接送平台、租借平台等。',
            },
            {
              icon: 'bot',
              title: '聊天機器⼈',
              content: '客服、行銷互動等聊天機器⼈。',
            },
          ],
          worksList: [
            {
              name: 'Holywater',
              wording:
                'Holywater 是⼀款天主教的⽣活服務軟體，當中將許多傳統宗教儀式改為線上執⾏模式，其更結合社群通訊功能（含直播、貼⽂、按讚、分享功能等），將宗教現代化，使教友在⽣活上更加便利。',
              servicesList: [],
            },
            {
              name: '愛兒麗',
              wording:
                '愛兒麗是⼀款護理之家專屬系統，該款 APP 使⽤ IOT 物聯網技術將設備介接⼿機，讓使⽤者可以隨時監控媽媽與寶寶身體徵象並予以紀錄，使護理之家在照顧媽媽與寶寶時更加⽅便。',
              servicesList: [],
            },
            {
              name: 'Evoxen',
              wording:
                'Evoxen 是⼀個與⾺來西亞停⾞場合作的專案，此款 APP 使⽤ IOT 物聯網系統將⾞位管理系統與⼿機介接，消費者停⾞後，會在⼿機產⽣專屬付費 QR Code，另外除了透過 QR Code 繳費外，該 APP 更結合了⾦融系統，讓消費者可以在線上繳交停⾞費，突破了傳統停⾞場的思維與運作⽅式。',
              servicesList: [],
            },
          ],
        }
        break
      case 'web':
        fetchedData = {
          name: '網站/系統',
          productType: [
            {
              icon: 'shop',
              title: '電子商務',
              content: '購物平台、金物流串接、訂單管理、會員管理系統等。',
            },
            {
              icon: 'social',
              title: '社交通訊',
              content: '社交平台、即時通訊、視訊聊天、交友媒合系統等。',
            },
            {
              icon: 'media',
              title: '線上媒體',
              content: '音樂廣播、影視戲劇、媒體播放器、行動直播、線上書店、線上可翻頁電子書等。',
            },
            {
              icon: 'platform',
              title: '內容平台',
              content: '新聞潮流、有聲書、部落格、論壇或其他內容平台。',
            },
            {
              icon: 'learn',
              title: '學習資源',
              content: '線上教學平台、語⾔學習系統、字典翻譯、百科全書等。',
            },
            {
              icon: 'health',
              title: '健康醫療',
              content: '運動健身網站、健康管理軟體、醫院診所系統等。',
            },
            {
              icon: 'business',
              title: '商業辦公',
              content: '資訊管理系統、電子文書系統、雲端儲存、POS系統串接等。',
            },
            {
              icon: 'qrcode',
              title: '電腦應⽤',
              content: '計算機、瀏覽器外掛、QRCode、鍵盤輸入法等。',
            },
            {
              icon: 'life',
              title: '⽣活服務',
              content:
                '食衣住行相關的搜尋和預訂系統、政府便民服務，地圖導航、房屋物件管理系統、物聯網軟體開發、美食地圖等。',
            },
            {
              icon: 'pay',
              title: '⾦融⽀付',
              content: '理財記帳、網路銀行、電⼦錢包、行動⽀付等。',
            },
            {
              icon: 'share',
              title: '共享經濟',
              content: '外送平台、交通接送平台、租借平台等。',
            },
            {
              icon: 'bot',
              title: '聊天機器⼈',
              content: '客服、行銷互動等聊天機器⼈。',
            },
          ],
          worksList: [
            {
              name: 'Nownews',
              wording:
                'Nownews 是⼀個網⾴新聞平台，技術範圍包含網⾴程式撰寫、使⽤者體驗規劃、網⾴編排、後台功能撰寫等︔於成果表現，智⽲創造了全國前⼗⼤流量的新聞平台，且該平台能夠承受極⼤瞬間流量。從該作品規模可以得知智⽲數位⾯對多⼤挑戰都可將之克服並完成。',
              servicesList: [],
            },
            {
              name: '雙雙個人形象網站',
              wording:
                '此作品為智禾協助個人建立形象網站，除了符合客製化需求，UX 或 UI 也都經過專業設計，且利用多種效果讓網站更加活潑，讓使用者在流程中可以有更大的互動意願。把網站需求交給智禾，專業感、設計感都將一次掌握。',
              servicesList: [],
            },
            {
              name: '九型人格',
              wording:
                '此作品為智禾協助業主建立一頁式功能網站，針對客製化需求串接金流，另也開設內容行銷版塊，讓商品可在網站內被內容包裝。另外，UX 或 UI 經過討論與專業設計，讓使用者流程更加順暢。由此作品可知，智禾可以針對不同的需求進行客製，打造比客戶心目中更加完美的網站。',
              servicesList: [],
            },
          ],
        }
        break
      default:
        break
    }

    setData((state) => fetchedData)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <StyledPage>
      <Header color="dark" />
      <Title title={`請選擇您的${data.name}需要哪些功能：`} color="dark" fullWidth="true" />
      <Container>
        <StyledList>
          <QuestionCard event={handleModalOpen} target="6" wording="智禾有哪些技術幫我實現？" />
          <QuestionCard event={handleModalOpen} target="3" wording="智禾作品有哪些？" />
        </StyledList>
        <TypeList space="4" productType={data.productType} />
      </Container>
    </StyledPage>
  )
}
