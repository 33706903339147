import React, { useRef, useEffect, createRef, useContext } from 'react'
import { TimelineMax, Back } from 'gsap'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { calculateFrame } from '../../common/fps'
import context from '../../context/GlobalContext'
import { Item } from './Item'
import styled from 'styled-components'
gsap.registerPlugin(CSSPlugin)

const StyledMenu = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.lg`
    border: none;
  `};
`

const StyledList = styled.ul`
  background: ${({ theme }) => theme.secondaryColor};
  display: flex;
  flex-direction: column;
  height: ${({ open }) => open ? 'auto' : '0'};
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding-left: 0;
  transition: height .2s;

  ${({ theme }) => theme.lg`
    background: transparent;
    flex-direction: row;
    height: auto;
    margin-left: auto;
  `};
`

const StyledListItem = styled.li`
  border-top: 1px solid ${({ theme }) => theme.primaryColor};
  text-align: center;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.primaryColor};
  }

  ${({ theme }) => theme.lg`
    border: none;
    padding: 20px 10px;

    &:last-child {
      border-bottom: none;
    }
  `};
`

const StyledBurgerBtn = styled.button`
  align-self: flex-end;
  background: transparent;
  border: none;
  display: inline-flex;
  line-height: 1;
  padding: 13px;

  &:focus {
    outline: 0;
  }

  ${({ theme }) => theme.sm`
    padding: 28px;
  `};

  ${({ theme }) => theme.lg`
    display: none;
  `};
`

export const Menu = (props) => {
  const { menuStatus, setMenuStatus, windowSize } = useContext(context)
  const menuData = [
    {
      title: '首頁',
      target: '0',
      ref: 'menuItem1',
    },
    {
      title: '服務產品',
      target: 'modalProduct',
      ref: 'menuItem2',
    },
    {
      title: '智禾作品集',
      target: 'modalWorks',
      ref: 'menuItem3',
    },
    {
      title: '關於智禾',
      target: 'modalAbout',
      ref: 'menuItem4',
    },
    {
      title: '聯絡智禾',
      target: 'modalContact',
      ref: 'menuItem4',
    },
  ]
  let refs = useRef([createRef(), createRef(), createRef(), createRef(), createRef()])
  let burger = useRef(null)

  useEffect(() => {
    if (windowSize.width > 768) {
      if (menuStatus.listPlayed === true) return
      listPlayed()
      let tl = new TimelineMax()
      tl.staggerFromTo(
        [
          refs.current[0].current,
          refs.current[1].current,
          refs.current[2].current,
          refs.current[3].current,
          refs.current[4].current,
        ],
        calculateFrame(4),
        {
          opacity: 0,
          y: -20,
        },
        {
          opacity: 1,
          y: 0,
          ease: Back.easeOut,
          delay: 7.5
        },
        0.1
        )
    } else {
      if (menuStatus.burgerPlayed === true) return
      burgerPlayed()
      let tl = new TimelineMax()
      tl.fromTo(burger.current, { opacity: 0 }, { opacity: 1, delay: .5 })
    }
  }, [])

  const closeMenu = () => {
    setMenuStatus(prevState => ({
      ...prevState,
      open: !prevState.open
    }))
  }

  const listPlayed = () => {
    setMenuStatus(prevState => ({
      ...prevState,
      listPlayed: !prevState.listPlayed
    }))
  }

  const burgerPlayed = () => {
    setMenuStatus(prevState => ({
      ...prevState,
      burgerPlayed: !prevState.burgerPlayed
    }))
  }

  return (
    <StyledMenu>
      <StyledBurgerBtn ref={burger} onClick={closeMenu}>
        {
          props.color === 'light' ?
          <img src="./assets/icon/menu_light.svg" alt="Toggle Menu"/>
          :
          <img src="./assets/icon/menu.svg" alt="Toggle Menu"/>
        }
      </StyledBurgerBtn>
      <StyledList open={menuStatus.open}>
        {menuData.map((item, idx) => {
          return (
            <StyledListItem ref={refs.current[idx]} key={idx}>
              <Item color={props.color} index={idx + 1} target={item.target}>{item.title}</Item>
            </StyledListItem>
          )
        })}
      </StyledList>
    </StyledMenu>
  )
}
