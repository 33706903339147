import React, { useContext } from 'react'
import context from '../../context/GlobalContext'
import styled from 'styled-components'

const StyledItem = styled.a`
  color: #f6db4c;
  cursor: pointer;
  display: block;
  font-size: 15px;
  line-height: 1.47;
  padding: 10px;

  ${({ theme }) => theme.lg`
    border-radius: 2px;
    color: ${(props) =>
      props.color === 'light' ? ({ theme }) => theme.primaryColor : ({ theme }) => theme.secondaryColor};
    margin-right: 24px;
    padding: 2px 10px;
    transition: 0.3s all;
  `}

  &:hover {
    background-color: #222;
    color: #f7db4c;
  }
`

export const Item = (props) => {
  const { setModalStatus, setListener, setCurrentPage, setMenuStatus } = useContext(context)

  const clickItem = (target, modalType) => {
    if (target.includes('modal')) {
      console.log('1111')
      setModalStatus((prevState) => (prevState = modalType))
      setListener((prevState) => (prevState = true))
      setMenuStatus((prevState) => ({
        ...prevState,
        open: !prevState.open,
      }))
    } else {
      setCurrentPage((prevState) => (prevState = parseInt(target, 10)))
      setMenuStatus((prevState) => ({
        ...prevState,
        open: !prevState.open,
      }))
    }
  }

  return (
    <StyledItem
      color={props.color}
      onClick={(e) => {
        e.preventDefault()
        clickItem(props.target, props.index)
      }}
    >
      {props.children}
    </StyledItem>
  )
}
