import React from 'react'
import styled from 'styled-components'

const StyledCard = styled.button`
	align-items: center;
	background-color: transparent;
	border-radius: 5px;
	border: solid 3px ${({ theme }) => theme.secondaryColor};
	color: ${({ theme }) => theme.secondaryColor};
	cursor: pointer;
	display: inline-flex;
	font-family: inherit;
	font-size: 18px;
	justify-content: center;
	margin: 1em 2em;
	outline: 0;
	padding: .7em 1.2em .7em 2em;
	position: relative;
	transition: all .2s;
	white-space: nowrap;
	
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.secondaryColor};
		color: ${({ theme }) => theme.primaryColor};
	}

	${({ theme }) => theme.lg`
		font-size: 24px;
	`};
`

const Icon = styled.div`
	align-items: center;
	display: inline-flex;
	left: 0;
	position: absolute;
	transform: translateX(-60%);
	width: 3em;
	
	${({ theme }) => theme.lg`
		transform: translateX(-54%);
	`};

	img {
		max-width: 100%;
	}
`

export const QuestionCard = (props) => {
	const handleClick = (e) => {
		const { event, target } = props
		if (event) event(target)
	}

	return(
		<StyledCard onClick={handleClick}>
			<Icon><img src="./assets/icon/question.svg" alt=""/></Icon>
			<span>{props.wording}</span>
		</StyledCard>
	)
}
