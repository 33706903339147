import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import context from '../../context/GlobalContext'
import Reaptcha from 'reaptcha'
import axios from 'axios'

const StyledModal = styled.div`
	padding: 20px 10px;

	${({ theme }) => theme.sm`
		background: ${({ theme }) => theme.secondaryColor};
		padding: 20px 10px 30px;
		margin: 0 20px;
	`}

	${({ theme }) => theme.md`
		margin: 0 40px 60px;
	`}
`
const StyledHeader = styled.div`
	color: ${({ theme }) => theme.primaryColor};
	font-size: 18px;
	margin-bottom: 1rem;

	${({ theme }) => theme.md`
		font-size: 20px;
	`};

	p {
		margin: 0;
		padding: 0;
	}
`
const StyledFormList = styled.div`
	align-items: ${(props) => (props.fullwidth ? '' : 'center')};
	display: flex;
	flex-direction: ${(props) => (props.fullwidth ? 'column' : 'row')};
	flex-wrap: wrap;
	font-size: 15px;
	padding: 5px;

	& + & {
		border-top: 3px solid ${({ theme }) => theme.primaryColor};
	}
`
const StyledLabel = styled.label.attrs((props) => ({
	htmlFor: props.htmlFor,
}))`
	color: ${({ theme }) => theme.primaryColor};
	flex: 1;
	padding: 10px 0;
	white-space: nowrap;
`
const StyledInput = styled.input.attrs((props) => ({
	id: props.id,
	required: props.required,
	type: props.type,
	name: props.name,
	value: props.value,
}))`
	background: transparent;
	border: none;
	color: ${({ theme }) => theme.whiteColor};
	flex: 3;
	font-family: inherit;
	font-size: inherit;
	padding: 10px 0;

	&::placeholder {
		color: ${({ theme }) => theme.whiteColor};
		opacity: 0.3;
	}

	&:focus {
		outline: 0;
	}
`
const StyledTextarea = styled.textarea.attrs((props) => ({
	id: props.id,
	required: props.required,
	type: props.type,
	name: props.name,
	value: props.value,
}))`
	background: transparent;
	border: none;
	color: ${({ theme }) => theme.whiteColor};
	font-family: inherit;
	font-size: inherit;
	height: 6em;
	padding: 0;

	&::placeholder {
		color: ${({ theme }) => theme.whiteColor};
		opacity: 0.3;
	}

	&:focus {
		outline: 0;
	}
`
const StyledRadio = styled.div`
	align-items: center;
	display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
	padding-right: ${(props) => (props.inline ? '30px' : '')};
	position: relative;

	input {
		position: absolute;
		visibility: hidden;
	}

	input:checked + label:after {
		background: ${({ theme }) => theme.primaryColor};
	}

	label {
		align-items: center;
		display: flex;
		padding: 0;

		&:before,
		&:after {
			box-sizing: border-box;
			content: '';
			display: inline-flex;
		}

		&:before {
			border: 3px solid ${({ theme }) => theme.primaryColor};
			height: 24px;
			width: 24px;
		}

		&:after {
			background: transparent;
			height: 10px;
			left: 7px;
			position: absolute;
			width: 10px;
		}

		span {
			padding-left: 10px;
		}
	}
`
const StyledButton = styled.input`
	background: ${({ theme }) => theme.primaryColor};
	border-radius: ${({ theme }) => theme.radius};
	border: 3px solid ${({ theme }) => theme.secondaryColor};
	box-shadow: 2px 2px 0 1px ${({ theme }) => theme.primaryColor};
	color: ${({ theme }) => theme.secondaryColor};
	display: block;
	font-family: inherit;
	font-size: inherit;
	margin: 10px auto 0;
	padding: 8px 50px 9px;

	&:disabled {
		background-color: #958639;
		box-shadow: 2px 2px 0 1px #958639;
	}

	&:hover:not(:disabled),
	&:focus:not(:disabled) {
		background: ${({ theme }) => theme.secondaryColor};
		color: ${({ theme }) => theme.primaryColor};
		box-shadow: 2px 2px 0 1px ${({ theme }) => theme.secondaryColor};
		border-color: ${({ theme }) => theme.primaryColor};
	}

	&:focus {
		outline: 0;
	}
`
const StyledModalCloseBtn = styled.img`
	display: flex;
	margin-top: -10px;
	position: absolute;
	z-index: 1;
	right: 10px;

	${({ theme }) => theme.md`
		display: none;
	`};
`

const Radio = (props) => {
	return (
		<StyledRadio inline={props.inline}>
			<StyledInput
				type="radio"
				name={props.name}
				value={props.value}
				id={props.id}
				checked={props.checked}
				onChange={props.onChange}
				required={props.required}
			/>
			<StyledLabel htmlFor={props.id}>
				<span>{props.labelName}</span>
			</StyledLabel>
		</StyledRadio>
	)
}

export const ModalContact = (props) => {
	const [userName, setUserName] = useState('')
	const [userEmail, setUserEmail] = useState('')
	const [userContent, setUserContent] = useState('')
	const [userPhone, setUserPhone] = useState('')
	const [userCompony, setUserCompony] = useState('')
	const [verified, setVerified] = useState(false)
	const [userCallStatus, setUserCallStatus] = useState(true)
	const { setModalStatus, setListener } = useContext(context)

	const closeModal = () => {
		setModalStatus((prevState) => (prevState = null))
		setListener((prevState) => (prevState = false))
	}

	const handleUserName = (e) => {
		e.persist()
		console.log(e.target.value)
		setUserName((prevState) => (prevState = e.target.value + '想與智禾聯繫'))
	}

	const handleUserEmail = (e) => {
		e.persist()
		setUserEmail((prevState) => (prevState = e.target.value))
	}

	const handleUserContent = (e) => {
		e.persist()
		setUserContent((prevState) => (prevState = e.target.value))
	}

	const handleUserPhone = (e) => {
		e.persist()
		setUserPhone((prevState) => (prevState = e.target.value))
	}

	const handleUserCompony = (e) => {
		e.persist()
		setUserCompony((prevState) => (prevState = e.target.value))
	}

	const handleUserChecked = (e) => {
		e.persist()
		setUserCallStatus((prevState) => (prevState = !prevState))
	}

	const onVerify = (recaptchaResponse) => {
		setVerified((prevState) => (prevState = true))
	}

	const submitHandler = (e) => {
		e.preventDefault()
		// TODO: Send E-mail API
		axios
			.post(`https://mailserver.scan2park.app/emailService`, {
				sendTo: 'chih.he.taiwan@gmail.com',
				title: userName,
				content: `${
					userCompony ? userCompony : userName
				}想與智禾聯繫,<br />他的信箱是 ${userEmail},<br />他的電話是 ${userPhone},<br /> ${
					userCallStatus ? '他願意接受專員電訪,' : '他不願意接受專員電訪,'
				}<br />他遇到了 ${userContent} 問題`,
			})
			.then((res) => {
				if (res.data.data.success) {
					closeModal()
				}
			})
	}

	return (
		<StyledModal>
			<StyledModalCloseBtn onClick={closeModal} src="./assets/icon/close_light.svg" />
			<StyledHeader>
				<p>聯絡智禾</p>
				<p>自己想，不如一起想。</p>
			</StyledHeader>
			<form onSubmit={submitHandler}>
				<StyledFormList>
					<StyledLabel htmlFor="formName">姓名*：</StyledLabel>
					<StyledInput
						type="text"
						placeholder="請輸入姓名"
						id="formName"
						onBlur={handleUserName}
						name="name"
						required
					/>
				</StyledFormList>
				<StyledFormList>
					<StyledLabel htmlFor="formPhone">電話*：</StyledLabel>
					<StyledInput
						type="text"
						placeholder="請輸入電話"
						id="formPhone"
						onBlur={handleUserPhone}
						name="phone"
						required
					/>
				</StyledFormList>
				<StyledFormList>
					<StyledLabel htmlFor="formEmail">信箱*：</StyledLabel>
					<StyledInput
						type="email"
						placeholder="請輸入信箱"
						id="formEmail"
						onBlur={handleUserEmail}
						name="email"
						required
					/>
				</StyledFormList>
				<StyledFormList>
					<StyledLabel htmlFor="formCompany">所屬公司：</StyledLabel>
					<StyledInput
						type="text"
						placeholder="請輸入所屬公司"
						onBlur={handleUserCompony}
						id="formCompany"
						name="company"
						required
					/>
				</StyledFormList>
				<StyledFormList>
					<StyledLabel>是否願意接受專員主動致電與您？*</StyledLabel>
					<StyledLabel>
						<Radio
							name="call"
							id="callTrue"
							value="true"
							checked={!userCallStatus}
							labelName="是"
							onChange={handleUserChecked}
							inline="true"
							required
						/>
						<Radio
							name="call"
							id="callFalse"
							checked={userCallStatus}
							labelName="否"
							value="false"
							onChange={handleUserChecked}
							inline="true"
							required
						/>
					</StyledLabel>
				</StyledFormList>
				<StyledFormList fullwidth>
					<StyledLabel>您遇到了哪些問題？</StyledLabel>
					<StyledTextarea placeholder="請填寫您的問題" onBlur={handleUserContent} />
				</StyledFormList>
				<Reaptcha sitekey="6LcH3P4UAAAAACiib02q8rqKsamKe-fSHif3Blxa" onVerify={onVerify} />
				<StyledFormList>
					<StyledButton type="button" disabled={!verified} value="送出" />
				</StyledFormList>
			</form>
		</StyledModal>
	)
}
