import React, { useRef, useEffect, useState } from 'react'
import { calculateFrame } from '../../common/fps'
import { TimelineMax } from 'gsap'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import styled from 'styled-components'
gsap.registerPlugin(CSSPlugin)

const StyledStatusCard = styled.div`
	align-items: center;
	background-color: #f6db4c;
	border-radius: 5px;
	color: ${({ theme }) => theme.secondaryColor};
	display: flex;
	flex: 1;
	font-size: 20px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 700;
	justify-content: center;
	letter-spacing: normal;
	line-height: 1.48;
	max-width: 100%;
	overflow: hidden;
	padding: 50px 0;
	position: relative;
	text-align: center;

	${({ theme }) => theme.sm`
		font-size: 27px;
	`}

	${({ theme }) => theme.md`
		font-size: 28px;
	`}

	${({ theme }) => theme.lg`
		font-size: 41px;
		padding: 67px 0;
	`}

	${({ theme }) => theme.xl`
		font-size: 50px;
	`}
`

const StyledLine = styled.div`
  background: ${({ theme }) => theme.secondaryColor};
  height: 5px;
  left: 0;
  position: absolute;
  top: 12%;
  width: 100%;
`

const StyledText = styled.div`
  align-items: center;
  display: flex;
  opacity: 1;
`

const StyledCountdown = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 1.5em;
  list-style: none;
  overflow: hidden;
  padding-left: 0;
`

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  transform: translateY(calc(-100% + 1.5em));
`

const StyledListItem = styled.li`
  height: 1.5em;
`

export const StatusCard = (props) => {
  let { wording, quantity, isPlay } = props
  const [status, setStatus] = useState(true)
  const [delay] = useState(props.delay)
  const [quantityList, setQuantityList] = useState([])
  const [tl] = useState(new TimelineMax())

  const card = useRef()
  const line = useRef()
  const text = useRef()
  const list = useRef()

  useEffect(() => {
    let arr = []
    for (var i = 1; i <= quantity; i++) {
      arr.push(i)
    }
    setQuantityList(arr)
  }, [])

  useEffect(() => {
    if (isPlay === false) {
      setStatus(false)
    }
  }, [isPlay])

  useEffect(() => {
    tl.delay(delay * calculateFrame(3))

    if (line.current !== null) {
      tl.fromTo(
        line.current,
        calculateFrame(35),
        {
          width: '0',
        },
        {
          width: '100%',
        }
      ).addLabel('lineReady')
    }

    if (text.current !== null) {
      tl.fromTo(
        text.current,
        calculateFrame(9),
        {
          opacity: 0,
          y: '60%',
        },
        {
          opacity: 1,
          y: '-20%',
        },
        'lineReady'
      ).to(text.current, calculateFrame(6), {
        opacity: 1,
        y: '0%',
      })
    }

    if (list.current !== null) {
      tl.fromTo(
        list.current,
        calculateFrame(9),
        {
          transform: 'translateY(0)',
        },
        {
          transform: 'translateY(calc(-100% + 1.5em))',
        },
        'lineReady'
      )
    }
  }, [status])

  return (
    <StyledStatusCard ref={card}>
      <StyledLine ref={line} />
      <StyledText ref={text}>
        {wording}：
        <StyledCountdown>
          <StyledList ref={list}>
            {quantityList.map((item, i) => {
              return <StyledListItem key={i}>{item}+</StyledListItem>
            })}
          </StyledList>
        </StyledCountdown>
      </StyledText>
    </StyledStatusCard>
  )
}
